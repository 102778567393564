import React from "react";
import { connect } from "react-redux";
import PlaceArea from "../../feature/place-area";

const mapDispatch = (dispatch) => ({
  setCooldown: (cooldownExpiryDate) =>
    dispatch.currentUser.setCooldown(cooldownExpiryDate),
  removeCooldown: () => dispatch.currentUser.removeCooldown(),
  tileSelected: (x, y) =>
    dispatch.tileDetails.tileSelected({ x, y, timestamp: Date.now() }),
  updateSelectedTileOwner: (provider, username, avatarUri, live, colorId) =>
    dispatch.tileDetails.updateSelectedTileOwner({
      provider,
      username,
      avatarUri,
      live,
      colorId,
    }),
  clearSelectedTile: () => dispatch.tileDetails.reset(),
});

export default connect(null, mapDispatch)(PlaceArea);
