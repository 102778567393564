import React from "react";
import { connect } from "react-redux";
import App from "../../App";

const mapProps = (state) => ({
  currentUser: state.currentUser,
});

const mapDispatch = (dispatch) => ({
  refreshUser: () => dispatch.currentUser.refreshSessionUser(),
});

export default connect(null, mapDispatch)(App);
