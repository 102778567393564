import React from "react";

const SVG = ({ className = "" }) => (
  <svg id="Layer_1" viewBox="0 0 129.19 132.52" className={className}>
    <polygon
      fill="#f1f333"
      points="55.27 107.12 36.45 124.71 38.47 99.02 13.87 106.71 26.84 84.44 1.35 80.69 22.69 66.26 1.35 51.82 26.84 48.07 13.87 25.81 38.47 33.49 36.45 7.81 55.27 25.4 64.6 1.38 73.92 25.4 92.75 7.81 90.73 33.49 115.32 25.81 102.36 48.07 127.85 51.82 106.51 66.26 127.85 80.69 102.36 84.44 115.32 106.71 90.73 99.02 92.75 124.71 73.92 107.12 64.6 131.13 55.27 107.12"
    />
    <path d="M64.6,2.76l9.13,23.51,18.42-17.22-1.98,25.14,24.07-7.52-12.69,21.79,24.95,3.67-20.89,14.13,20.89,14.13-24.95,3.67,12.69,21.79-24.07-7.52,1.98,25.14-18.42-17.22-9.13,23.51-9.13-23.51-18.42,17.22,1.98-25.14-24.07,7.52,12.69-21.79-24.95-3.67,20.89-14.13L2.69,52.13l24.95-3.67L14.95,26.67l24.07,7.52-1.98-25.14,18.42,17.22L64.6,2.76m0-2.76l-.93,2.4-8.59,22.13L37.73,8.32l-1.88-1.76,.2,2.57,1.86,23.66-22.66-7.08-2.46-.77,1.3,2.23,11.94,20.51-23.48,3.45-2.55,.37,2.13,1.44,19.66,13.3L2.13,79.56l-2.13,1.44,2.55,.37,23.48,3.45-11.94,20.51-1.3,2.23,2.46-.77,22.66-7.08-1.86,23.66-.2,2.57,1.88-1.76,17.34-16.21,8.59,22.13,.93,2.4,.93-2.4,8.59-22.13,17.34,16.21,1.88,1.76-.2-2.57-1.86-23.66,22.66,7.08,2.46,.77-1.3-2.23-11.94-20.51,23.48-3.45,2.55-.37-2.13-1.44-19.66-13.3,19.66-13.3,2.13-1.44-2.55-.37-23.48-3.45,11.94-20.51,1.3-2.23-2.46,.77-22.66,7.08,1.86-23.66,.2-2.57-1.88,1.76-17.34,16.21L65.53,2.4l-.93-2.4h0Z" />
  </svg>
);

export default SVG;
