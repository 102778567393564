import React from 'react';
import { connect } from "react-redux";
import PlaceTileDetails from "../../feature/place-area/place-tile-details";

const mapProps = state => {
  const {
    fetchingSelectedTile,
    showSelectedTile,
    selectedTile,
    currentCoords,
  } = state.tileDetails;

  const {
    isModerator
  } = state.currentUser

  const tile = fetchingSelectedTile ? null : selectedTile;

  return {
    fetchingDetails: fetchingSelectedTile,
    showDetails: showSelectedTile,
    tileX: currentCoords?.x || 0,
    tileY: currentCoords?.y || 0,
    userAvatarUri: tile?.user.avatarUri,
    userLive: tile?.user.live,
    username: tile?.user.username,
    provider: tile?.user.provider,
    providerId: tile?.user.providerId,
    isBanned: tile?.user.isBanned || false,
    currentUserIsModerator: isModerator,
  };
};

const mapDispatch = (dispatch) => ({
  handleBanUnbanUser: (provider, providerId, isBanned) =>
    dispatch.tileDetails.banUnbanUser({
      provider,
      providerId,
      isBanned
    }),
});

export default connect(mapProps, mapDispatch)(PlaceTileDetails);