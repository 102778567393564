import React,{ useState } from 'react';

export default function StyledButton({
  className = '',
  onClick,
  disabled = false,
  children
}) {
  return (
    <div className="flex p-1">
      <button
        onClick={onClick}
        className={`button ${className}`}
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  )
}
