import React from 'react'

export default function CooldownTimer({days = 0, hours = 0, minutes = 0, seconds = 0}) {
  const formattedDays = days.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  const formattedHours = hours.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  const formattedMinutes = minutes.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  const formattedSeconds = seconds.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

  const prepend = `${days > 0 ? `${formattedDays}:` : ''}${hours > 0 ? `${formattedHours}:` : ''}`;

  return (
    <div className="font-mono font-bold text-lg sm:text-base inline-flex flex-row px-4 py-1 sm:px-2 sm:py-0.5 justify-center items-center bg-black text-white select-none">
      COOLDOWN: {prepend}
      {formattedMinutes}:{formattedSeconds}
    </div>
  );
}
