import { install } from "@pixi/unsafe-eval";
import { ShaderSystem } from "@pixi/core";
import { Application, settings, SCALE_MODES, Point } from "pixi.js";
import { Viewport } from "pixi-viewport";
import Canvas from "./canvas";
import { trigger } from "../../../services/events";

const CANVAS_WIDTH = 128;
const CANVAS_HEIGHT = 128;

export default class PlaceApplication {
  constructor(width = 600, height = 400, options = {}) {
    // Set Up DateTime Tracking
    this.applicationStart = Date.now();

    // Install Unsafe-eval Fix For Pixi.js
    install({ ShaderSystem });

    // Set Up Pixi.js Application
    this.application = new Application({
      width,
      height,
      backgroundColor: 0x333333,
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
      disableOnContextMenu: true,
    });
    settings.SCALE_MODE = SCALE_MODES.NEAREST;

    // Build Viewport
    this.buildViewport(width, height);
    this.application.stage.addChild(this.viewport);

    // Build Canvas
    this.canvas = new Canvas(CANVAS_WIDTH, CANVAS_HEIGHT);
    this.viewport.addChild(this.canvas.getMap());

    // Handle Pointer Down Event
    this.pointerDragStart = { x: 0, y: 0 };
    this.isDragging = false;

    this.application.renderer.on("resize", (e) => {
      const { clientWidth: width, clientHeight: height } =
        this.application.resizeTo;
      this.viewport.resize(width, height);
    });

    this.viewport.on("clicked", (e) => {
      const x = Math.floor(e.world.x);
      const y = Math.floor(e.world.y);
      if (x < 0) return;
      if (x >= CANVAS_WIDTH) return;
      if (y >= CANVAS_HEIGHT) return;
      if (y < 0) return;
      this.tileSelected(x, y);
    });

    this.viewport.on("wheel", (e) => {
      this.viewport.plugins.remove("animate");
    });

    this.viewport.on("pinch-start", (e) => {
      this.viewport.plugins.remove("animate");
    });

    // Vars
    this.selectedColorId = 0;
    this.selectedTile = {
      x: 0,
      y: 0,
    };
    this.ready = false;

    this.update();
  }

  buildViewport(width, height) {
    const bleed = 10;

    this.viewport = new Viewport({
      screenWidth: width,
      screenHeight: height,
      worldWidth: CANVAS_WIDTH,
      worldHeight: CANVAS_HEIGHT,
      interaction: this.application.renderer.plugins.interaction,
      passiveWheel: false,
    });
    this.viewport
      .clamp({
        left: -bleed,
        right: this.viewport.worldWidth + bleed,
        top: -bleed,
        bottom: this.viewport.worldHeight + bleed,
        underflow: "center",
      })
      .clampZoom({
        minScale: 0.75,
        maxScale: 50,
      })
      .drag()
      .pinch()
      .wheel({
        smooth: 15,
      });
  }

  centerViewport() {
    this.viewport.moveCenter(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2);
  }

  refreshView() {
    const { clientWidth: width, clientHeight: height } =
      this.application.resizeTo;
    this.application.resize();
    this.viewport.resize(width, height);
    this.centerViewport();
  }

  start(onComplete) {
    this.ready = true;
    this.centerViewport();
    this.viewport.setZoom(2);
    onComplete(this.getApplicationView());
  }

  stop() {
    this.ready = false;
    this.application.stop();
  }

  setParent(htmlElement) {
    this.application.resizeTo = htmlElement;
  }

  colorSelected(colorId) {
    this.selectedColorId = colorId;
    if (!this.canvas.getPreviewTileEnabled()) {
      this.canvas.enablePreviewTile();
    }
    this.canvas.setPreviewTileColor(colorId);
  }

  getSelectedColor() {
    return this.selectedColorId;
  }

  tileSelected(x, y) {
    this.viewport.animate({
      time: 500,
      position: new Point(x, y),
      interrupt: true,
      removeOnComplete: true,
      removeOnInterrupt: true,
      scale: 30,
      ease: "easeOutCubic",
    });

    this.selectedTile = { x, y };
    this.canvas.setSelectionBoxPosition(x, y);
    this.canvas.setPreviewTilePosition(x, y);
    // can use event to retrieve data about the tile
    trigger("tileSelected", { x, y });
  }

  getSelectedTile() {
    return this.selectedTile;
  }

  setTilePreview(enabled) {
    enabled === true
      ? this.canvas.enablePreviewTile()
      : this.canvas.disablePreviewTile();
  }

  placeTile() {
    const { x, y } = this.selectedTile;
    this.addTileAtLocation(x, y, this.selectedColorId);
  }

  placeTemporaryTile() {
    const { x, y } = this.selectedTile;
    this.canvas.addTemporaryTile(x, y, this.selectedColorId);
  }

  removeTemporaryTile() {
    this.canvas.removeTemporaryTile();
  }

  revertTemporaryTile() {
    this.canvas.revertTemporaryTile();
  }

  addTileAtLocation(x, y, colorId) {
    this.canvas.addTile(x, y, colorId);
  }

  getApplicationView() {
    return this.application.view;
  }

  update() {
    if (this.ready) {
      this.application.render(this.application.stage);
    }
    requestAnimationFrame(this.update.bind(this));
  }
}
