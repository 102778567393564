import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./containers/Home";
import PrivacyPolicy from "./pages/privacyPolicy";
import Auth from "./pages/auth";
import Header from "./containers/Header";
import Footer from "./feature/footer";
import ScrollToTop from "./components/scroll-to-top";

function App({ refreshUser }) {
  useEffect(() => {
    refreshUser();
  }, []);

  return (
    <div className="bg-violet-100">
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/auth/complete" element={<Auth />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
