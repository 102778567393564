import React from "react";
import MinimizeIcon from "../../../assets/icons/minimize";
import MaximizeIcon from "../../../assets/icons/maximize";

export default function ExpandToggle({
  expandSetting,
  onClick,
  className = "",
}) {
  return (
    <button
      className={`flex transition-all duration-75 bg-white hover:bg-slate-100 active:bg-slate-200 p-2 rounded border-2 border-black cursor-pointer select-none ${className}`}
      onClick={onClick}
    >
      {expandSetting === true ? (
        <MinimizeIcon className="w-6 md:w-4" />
      ) : (
        <MaximizeIcon className="w-6 md:w-4" />
      )}
    </button>
  );
}
