import React, { useEffect, useState } from "react";
import useInterval from "../../../hooks/useInterval";
import TileControls from "./tile-controls";
import CooldownTimer from "../cooldown-timer";
import { calculateTimeUntil } from "../../../services/time";
import Button from "../../../components/input/button";
import Twemoji from "../../../components/twemoji";
import TwitchIcon from "../../../assets/icons/twitch-icon";
import DotPattern from "../../../components/dot-pattern";
import { createTwitchAuthPopupWindow } from "../../../services/window";

export default function PlaceControls(props) {
  const {
    // Normal Props
    selectedColor,
    onColorSelected,
    onPlaceTile,
    onCancel,
    showControls,
    // Connect Props
    currentUser,
    cooldownExpiryDate,
    removeCooldown,
  } = props;

  const [cooldownTimeLeft, setCooldownTimeLeft] = useState(null);
  const [cooldownActive, setCooldownActive] = useState(false);

  useInterval(
    () => {
      updateCooldown();
    },
    cooldownActive ? 1000 : null
  );

  useEffect(() => {
    if (cooldownExpiryDate !== null && Date.now() < cooldownExpiryDate) {
      setCooldownActive(true);
      setCooldownTimeLeft(calculateTimeUntil(cooldownExpiryDate));
    } else {
      setCooldownActive(false);
      setCooldownTimeLeft(null);
    }
  }, [cooldownExpiryDate]);

  const updateCooldown = () => {
    if (cooldownActive) {
      const timeUntilExpiry = calculateTimeUntil(cooldownExpiryDate);
      setCooldownTimeLeft(timeUntilExpiry);

      if (!timeUntilExpiry.total > 0) removeCooldown();
    }
  };

  const SignInButton = () => (
    <Button onClick={() => createTwitchAuthPopupWindow()}>
      <TwitchIcon className="w-5 mr-2" />
      Connect Twitch
    </Button>
  );

  const InsetContainer = ({ children }) => (
    <div className="z-10 flex items-center justify-center absolute inset-0">
      {children}
    </div>
  );

  const ControlInfoMessages = ({
    cooldownActive,
    cooldownTimeLeft,
    isSignedIn,
  }) =>
    cooldownActive || !isSignedIn ? (
      <InsetContainer>
        {!isSignedIn && <SignInButton />}
        {cooldownActive && (
          <CooldownTimer
            days={cooldownTimeLeft?.days}
            hours={cooldownTimeLeft?.hours}
            minutes={cooldownTimeLeft?.minutes}
            seconds={cooldownTimeLeft?.seconds}
          />
        )}
      </InsetContainer>
    ) : null;

  return (
    <div className="flex flex-col relative mt-2">
      <DotPattern className="absolute w-full h-full" />

      <div
        className={`z-10 mt-3 flex flex-col ${
          !currentUser.isSignedIn || !showControls || cooldownActive
            ? "opacity-20 pointer-events-none"
            : ""
        }`}
      >
        <TileControls
          selectedColor={selectedColor}
          onColorSelected={onColorSelected}
        />
      </div>

      <div
        className={`z-10 mb-3 flex flex-col-reverse md:flex-row md:items-center md:justify-center flex-wrap mt-5 ${
          !currentUser.isSignedIn || !showControls || cooldownActive
            ? "opacity-20 pointer-events-none"
            : ""
        }`}
      >
        <Button
          onClick={onCancel}
          className="bg-red-200 hover:bg-red-300 active:bg-red-400 w-full md:w-40"
        >
          <Twemoji emoji="🚫" className="w-5 mr-2" /> Cancel
        </Button>
        <Button
          onClick={onPlaceTile}
          className={`w-full md:w-40 md:ml-3 disabled:cursor-not-allowed disabled:bg-zinc-300 disabled:translate-y-mimic-active disabled:-translate-x-mimic-active disabled:shadow-none`}
          disabled={selectedColor === -1}
        >
          <Twemoji emoji="✨" className="w-5 mr-2" /> Place Tile
        </Button>
      </div>

      <ControlInfoMessages
        cooldownActive={cooldownActive}
        cooldownTimeLeft={cooldownTimeLeft}
        isSignedIn={currentUser.isSignedIn}
      />
    </div>
  );
}
