import React, { useRef, useEffect } from "react";
import { requestCanvas } from "../../../services/api";

function PlaceCanvas({ application: app, expandSetting }) {
  const containerRef = useRef(null);

  const init = () => {
    app.setParent(containerRef.current);
    app.start(async (view) => {
      const { success, canvas } = await requestCanvas();

      if (!success) return console.error("error requesting canvas");

      canvas.forEach((tile) => {
        const { position, colorId } = tile;
        const [x, y] = String(position).split(",");
        app.addTileAtLocation(x, y, colorId);
      });

      containerRef.current.appendChild(view);
    });
  };

  const exit = () => {
    app.stop();
    if (containerRef.current) containerRef.current.innerHTML = "";
  };

  useEffect(() => {
    init();
    return () => exit();
  }, []);

  useEffect(() => {
    app.refreshView();
  }, [expandSetting]);

  return (
    <div
      style={{ height: expandSetting ? "800px" : "530px" }}
      className="w-full"
      ref={containerRef}
    />
  );
}

export default React.memo(PlaceCanvas);
