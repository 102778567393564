import React from "react";

const SVG = ({
    fill = "currentColor",
    className = "",
}) => (
    <svg fill={fill} role="img" aria-hidden="true" className={className}>
      <title>Dot Regular</title>
      <pattern id="dot-pattern" x="0" y="0" width="6" height="6" patternUnits="userSpaceOnUse">
        <circle cx="0.5" cy="0.5" r="0.5" fill={fill}></circle>
      </pattern>
      <rect width="100%" height="100%" fill="url(#dot-pattern)"></rect>
    </svg>
);

export default SVG;