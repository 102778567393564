import React from "react";

const SVG = ({ fill = "currentColor", className = "" }) => (
  <svg fill={fill} className={className}>
    <pattern
      id="Diagonal"
      x="0"
      y="0"
      width="4"
      height="4"
      patternUnits="userSpaceOnUse"
    >
      <rect width="1" height="1" fill="currentColor"></rect>
      <rect x="2" y="2" width="1" height="1" fill="currentColor"></rect>
      <rect x="1" y="2" width="1" height="1" fill="currentColor"></rect>
      <rect x="1" y="3" width="1" height="1" fill="currentColor"></rect>
      <rect y="3" width="1" height="1" fill="currentColor"></rect>
      <rect x="3" width="1" height="1" fill="currentColor"></rect>
      <rect x="3" y="1" width="1" height="1" fill="currentColor"></rect>
      <rect x="2" y="1" width="1" height="1" fill="currentColor"></rect>
    </pattern>
    <rect width="100%" height="100%" fill="url(#Diagonal)"></rect>
  </svg>
);

export default SVG;
