import { getCookie } from "../cookie";

const rootUrl = "https://glitchd.place/api";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Credentials": true,
};

export const getAuthenticatedUser = () =>
  fetch(`${rootUrl}/auth/`, {
    method: "GET",
    credentials: "include",
    headers: {
      ...headers,
      "csrf-token": getCookie("csrf-token"),
    },
  }).then((res) => res.json());

export const requestLogout = () =>
  fetch(`${rootUrl}/auth/logout`, {
    method: "POST",
    credentials: "include",
    headers: {
      ...headers,
      "csrf-token": getCookie("csrf-token"),
    },
    body: JSON.stringify({}),
  }).then((res) => res.json());

export const requestCanvas = () =>
  fetch(`${rootUrl}/place/tiles`, {
    method: "GET",
    credentials: "include",
    headers: {
      ...headers,
      "csrf-token": getCookie("csrf-token"),
    },
  }).then((res) => res.json());

export const requestPlaceTile = (x, y, colorId) =>
  fetch(`${rootUrl}/place/tiles`, {
    method: "POST",
    credentials: "include",
    headers: {
      ...headers,
      "csrf-token": getCookie("csrf-token"),
    },
    body: JSON.stringify({
      x,
      y,
      colorId,
      timestamp: Date.now(),
    }),
  }).then((res) => res.json());

export const requestTileDetails = (x, y, timestamp) =>
  fetch(`${rootUrl}/place/tile?x=${x}&y=${y}&timestamp=${timestamp}`, {
    method: "GET",
    credentials: "include",
    headers: {
      ...headers,
      "csrf-token": getCookie("csrf-token"),
    },
  }).then((res) => res.json());

export const requestSetUserIsBanned = (provider, providerId, isBanned) =>
  fetch(`${rootUrl}/users/ban`, {
    method: "POST",
    credentials: "include",
    headers: {
      ...headers,
      "csrf-token": getCookie("csrf-token"),
    },
    body: JSON.stringify({
      provider,
      providerId,
      isBanned
    }),
  }).then((res) => res.json());

export const getLeaderboards = () =>
  fetch(`${rootUrl}/leaderboards`, {
    method: "GET",
    credentials: "include",
    headers: {
      ...headers,
      "csrf-token": getCookie("csrf-token"),
    },
  }).then((res) => res.json());
