import React, { useEffect, useState } from "react";
import { getLeaderboards } from "../../services/api";
import Leaderboard from "../../components/leaderboard";

export default function Leaderboards() {

  const [placedTileLeaderboard, setPlacedTileLeaderboard] = useState(null);
  const [activeTileLeaderboard, setActiveTileLeaderboard] = useState(null);

  const updateLeaderboards = async () => {
    const response = await getLeaderboards();
    setPlacedTileLeaderboard(
      response.leaderboards.placedTiles.sort((a, b) => b.score - a.score)
    );
    setActiveTileLeaderboard(
      response.leaderboards.activeTiles.sort((a, b) => b.score - a.score)
    );
  };

  useEffect(() => {
    updateLeaderboards();
    const leaderboardInterval = setInterval(
      () => updateLeaderboards(),
      1000 * 60
    );
    return () => {
      clearInterval(leaderboardInterval);
    };
  }, []);

  return (
    <>
      <Leaderboard
        title="Most Active Tiles"
        users={activeTileLeaderboard}
        showLoading={placedTileLeaderboard === null}
      />
      <Leaderboard
        title="Most Placed Tiles"
        users={placedTileLeaderboard}
        showLoading={activeTileLeaderboard === null}
      />
    </>
  );
}
