import { getAuthenticatedUser, requestLogout } from "../../services/api";

const initialState = {
  provider: null,
  username: null,
  avatarURI: null,
  live: null,
  cooldownExpiryDate: null,
  isSignedIn: false,
  isModerator: false,
};

export const currentUser = {
  state: initialState,
  reducers: {
    set: (state, user) => ({
      // ...state,
      ...user,
      isSignedIn: true,
    }),
    reset: (state, payload) => initialState,
    setCooldown: (state, cooldownExpiryDate) => ({
      ...state,
      cooldownExpiryDate,
    }),
    removeCooldown: (state) => ({
      ...state,
      cooldownExpiryDate: null,
    }),
  },
  effects: (dispatch) => ({
    async refreshSessionUser() {
      const {
        user,
        cooldownExpiry,
        isModerator
      } = await getAuthenticatedUser();

      if (!user) return dispatch.currentUser.reset();

      const cooldownExpiryDate =
        cooldownExpiry ? Date.now() + cooldownExpiry : null;

      dispatch.currentUser.set({
        provider: user.provider,
        username: user.username,
        avatarURI: user.avatarUrl,
        live: user.live,
        cooldownExpiryDate,
        isModerator
      });
    },
    logout() {
      requestLogout();
      dispatch.currentUser.reset();
    },
  }),
};
