import React from "react";

const SVG = ({
  stroke = "currentColor",
  strokeWidth = "2",
  className = "",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    // height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <path d="M21 2H3v16h5v4l4-4h5l4-4V2zm-10 9V7m5 4V7"></path>
  </svg>
);

export default SVG;
