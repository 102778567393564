import React from "react";
import PageContainer from "../../components/page-container";
import Logo from "../../assets/logo";
import TwitterIcon from "../../assets/icons/feather-twitter";
import YouTubeIcon from "../../assets/icons/feather-youtube";
import TwitchIcon from "../../assets/icons/feather-twitch";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="mt-16 py-5 border border-t-black bg-violet-50 font-hkGrotesk">
      <PageContainer>
        <div className="flex flex-col md:flex-row flex-1">
          <div className="flex flex-col basis-1/2 space-y-2 items-center md:items-baseline mt-3 md:mt-0">
            <Logo className="flex w-16 grayscale mt-2 md:mt-0" />
            <div className="flex flex-col order-first md:order-none space-y-1 md:space-y-0 text-center md:text-left">
              <div className="text-sm">
                Glitch'd Place © The Everything Project, LLC.
              </div>
              <div className="text-sm">
                Glitch'd Place is not affiliated with Twitch or Amazon.
              </div>
              <div className="text-sm">
                All Trademarks referred to are the property of their respective
                owners.
              </div>
              <div className="text-sm underline">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col order-first md:order-none basis-1/2 space-y-2 md:mt-0">
            <div className="font-bold text-center md:text-left">Socials</div>
            <ul className="list-none flex-col md:flex-row flex-1 space-y-3 md:space-y-0 md:space-x-2 justify-around items-center">
              <li className="md:inline-flex items-center">
                <a
                  href="https://twitter.com/glitchdplace"
                  target="_blank"
                  rel="noreferrer"
                  className="flex hover:underline items-center space-x-2 md:space-x-1 justify-center md:justify:baseline"
                >
                  <TwitterIcon className="w-5 md:w-4" />
                  <span>@glitchdplace</span>
                </a>
              </li>
              <li className="md:inline-flex items-center">
                <a
                  href="https://twitter.com/everythingproj"
                  target="_blank"
                  rel="noreferrer"
                  className="flex hover:underline items-center space-x-2 md:space-x-1 justify-center md:justify:baseline"
                >
                  <TwitterIcon className="w-5 md:w-4" />
                  <span>@everythingproj</span>
                </a>
              </li>
              <li className="md:inline-flex items-center">
                <a
                  href="https://www.youtube.com/channel/UC6RcZ7hYXLkfQuWGXQ2xntg"
                  target="_blank"
                  rel="noreferrer"
                  className="flex hover:underline items-center space-x-2 md:space-x-1 justify-center md:justify:baseline"
                >
                  <YouTubeIcon className="w-5 md:w-4" />
                  <span>The Everything Project</span>
                </a>
              </li>
              <li className="md:inline-flex">
                <a
                  href="https://www.twitch.tv/everythingproject"
                  target="_blank"
                  rel="noreferrer"
                  className="flex hover:underline items-center space-x-2 md:space-x-1 justify-center md:justify:baseline"
                >
                  <TwitchIcon className="w-5 md:w-4" />
                  <span>everythingproject</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </PageContainer>
    </div>
  );
}
