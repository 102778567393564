import React from "react";
import Logo from "../../assets/logo";
import DropdownIcon from "../../assets/icons/dropdown";
import Button from "../../components/input/button";
import { Link } from "react-router-dom";

const UserPanel = ({ username, avatarURI, onLogout }) => (
  <div className="flex items-center justify-center space-x-4">
    <span className="hidden sm:flex font-hkGrotesk text-base">{username}</span>
    <img
      src={avatarURI}
      className="w-8 rounded-full shadow-mimic-0"
      alt={`${username}'s Avatar`}
    />
    {/* <Button onClick={() => null} className='p-1.5 shadow-mimic-0'><DropdownIcon /></Button> */}
    <Button onClick={onLogout} className="p-1.5 shadow-mimic-0">
      <span className="text-xs">Logout</span>
    </Button>
  </div>
);

export default function Header({ currentUser, handleLogout }) {
  return (
    <div className="flex bg-white">
      <div className="flex flex-1 justify-center items-center max-w-screen-xl px-2 md:px-3 py-4 mx-auto">
        <div className="flex flex-1">
          <Link to="/" className="focus:ring ring-offset-4 ring-black">
            <Logo className="w-20" />
          </Link>
        </div>

        {currentUser.isSignedIn ? (
          <UserPanel
            username={currentUser.username}
            avatarURI={currentUser.avatarURI}
            onLogout={handleLogout}
          />
        ) : null}
      </div>
    </div>
  );
}
