import React from 'react';
import { connect } from "react-redux";
import PlaceControls from "../../feature/place-area/place-controls";

const mapProps = state => ({
  currentUser: state.currentUser,
  cooldownExpiryDate: state.currentUser.cooldownExpiryDate,
});

const mapDispatch = dispatch => ({
  setCooldown: cooldownExpiryDate =>
    dispatch.currentUser.setCooldown(cooldownExpiryDate),
  removeCooldown: () =>
    dispatch.currentUser.removeCooldown(),
});

export default connect(mapProps, mapDispatch)(PlaceControls);