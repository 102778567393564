import React, { useEffect, useState } from "react";
import Application from "./place-canvas/application";
import PlaceCanvas from "./place-canvas";
import PlaceControls from "../../containers/PlaceControls";
import { on, off } from "../../services/events";
import { onAddTile } from "../../services/websocket";
import { requestPlaceTile } from "../../services/api";
import SoundTileSelected from "../../assets/sound/click.wav";
import SoundTilePlaced from "../../assets/sound/submit.wav";
import VolumeToggle from "./volume-toggle";
import ExpandToggle from "./expand-toggle";
import Card from "../../components/card";
import PlaceTileDetails from "../../containers/PlaceTileDetails";
import { Howl, Howler } from "howler";

const app = new Application();

export default function PlaceArea(props) {
  const {
    setCooldown,
    removeCooldown,
    tileSelected,
    updateSelectedTileOwner,
    clearSelectedTile,
  } = props;

  const [selectedColor, setSelectedColor] = useState(-1);
  const [showTileControls, setShowTileControls] = useState(false);

  // 3 = HIGH, 2 = MEDIUM, 1 = LOW, 0 = MUTE
  const [volumeSetting, setVolumeSetting] = useState(3);
  const [expandSetting, setExpandSetting] = useState(false);

  const tileSelectedSound = new Howl({
    src: [SoundTileSelected],
  });

  const tilePlacedSound = new Howl({
    src: [SoundTilePlaced],
  });

  useEffect(() => {
    on("tileSelected", ({ detail }) => onTileSelected(detail));
    onAddTile((x, y, colorId) => {
      app.addTileAtLocation(x, y, colorId);

      const { x: selectedX, y: selectedY } = app.getSelectedTile();

      if (x === selectedX && y === selectedY) {
        tileSelected(x, y);
      }
    });
    clearSelectedTile();

    return () => {
      off("tileSelected", ({ detail }) => onTileSelected(detail));
      clearSelectedTile();
    };
  }, []);

  useEffect(() => {
    switch (volumeSetting) {
      case 3:
        Howler.mute(false);
        Howler.volume(0.75);
        break;
      case 2:
        Howler.volume(0.5);
        break;
      case 1:
        Howler.volume(0.25);
        break;
      case 0:
        Howler.mute(true);
        Howler.volume(0);
        break;
      default:
        Howler.mute(false);
        Howler.volume(0.75);
    }
  }, [volumeSetting]);

  const onTileSelected = ({ x, y }) => {
    tileSelected(x, y);
    setShowTileControls(true);
    tileSelectedSound.play();
  };

  const onColorSelected = (colorId) => {
    app.colorSelected(colorId);
    app.setTilePreview(true);
    setSelectedColor(colorId);
  };

  const onCancel = () => {
    app.colorSelected(0);
    app.setTilePreview(false);
    setShowTileControls(false);
    setSelectedColor(-1);
  };

  const onPlaceTile = () => {
    const selectedTile = app.getSelectedTile();
    const selectedColor = app.getSelectedColor();
    tilePlacedSound.play();

    requestPlaceTile(selectedTile.x, selectedTile.y, selectedColor).then(
      (res) => {
        if (!res.success) {
          app.revertTemporaryTile();
          return removeCooldown();
        }
        app.removeTemporaryTile();
        setCooldown(res.cooldownExpiry);
      }
    );

    let cooldownDate = Date.now();
    cooldownDate += 5000;
    setCooldown(cooldownDate);
    app.placeTemporaryTile();
    app.colorSelected(0);
    app.setTilePreview(false);
    setSelectedColor(-1);
  };

  const onVolumeChange = () => {
    let newVolume = volumeSetting - 1;
    if (newVolume < 0) newVolume = 3;
    setVolumeSetting(newVolume);
  };

  return (
    <div className={`${props.className} space-y-2 md:space-y-8`}>
      <div className="flex flex-col md:flex-row">
        <Card className="relative overflow-hidden md:basis-5/6">
          <PlaceCanvas application={app} expandSetting={expandSetting} />
          <div className="flex absolute bottom-1 right-1 md:space-x-1">
            <ExpandToggle
              checked={false}
              expandSetting={expandSetting}
              className="hidden md:flex"
              onClick={() => setExpandSetting(!expandSetting)}
            />
            <VolumeToggle
              volumeSetting={volumeSetting}
              onClick={() => onVolumeChange()}
            />
          </div>
        </Card>
        <Card className="md:basis-1/6 mt-2 md:mt-0 md:ml-2">
          <PlaceTileDetails />
        </Card>
      </div>

      <PlaceControls
        selectedColor={selectedColor}
        onColorSelected={onColorSelected}
        onPlaceTile={onPlaceTile}
        onCancel={onCancel}
        showControls={showTileControls}
      />
    </div>
  );
}
