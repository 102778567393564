import React from "react";

const SVG = ({
    className = "",
}) => (
    <svg id="Layer_1" viewBox="0 0 246.94 119.27" className={className}>
      <g>
        <path d="M.08,32.15C.14,18.14,8.49,6.48,21.56,6.53c9.03,.04,14.35,4.03,17.86,12.13l-5.86,2.86c-2.36-5.71-6.17-9.05-12.31-9.08-9.1-.04-14.34,9.9-14.37,18.71v1.95c-.04,8.81,5.11,19.08,14.86,19.12,5.7,.02,11.64-3.34,11.67-12.08v-3.54l-13.27-.06,.02-5.92,20.07,.08-.04,8.88c-.05,11.19-9.1,18.59-18.99,18.55C6.54,58.1,.02,46.16,.08,32.15Z"/>
        <path d="M47.94,0l6.21,.03-.24,57.76-6.21-.03,.24-57.76Z"/>
        <path d="M68.72,3.26c2.89,.01,5.26,2.4,5.25,5.29-.01,2.89-2.4,5.19-5.29,5.18-2.89-.01-5.19-2.33-5.18-5.22,.01-2.89,2.33-5.26,5.22-5.25Zm-3.18,18.47l6.21,.03-.15,36.1-6.21-.03,.15-36.1Z"/>
        <path d="M86.38,47.16l.08-20.14-8.23-.03,.02-5.2,8.23,.03,.04-10.69,6.21,.03-.04,10.69,13.86,.06-.02,5.2-13.86-.06-.08,20.14c-.02,4.26,2.14,6.44,5.46,6.45,3.11,.01,5.71-2.14,7.18-6.9l4.75,2.69c-2.48,6.85-6.97,9.43-12.39,9.41-7.29-.03-11.25-3.73-11.22-11.67Z"/>
        <path d="M113.63,40.13c.04-9.6,7.66-18.6,17.91-18.56,9.24,.04,15.15,4.03,16.77,12.2l-6,1.85c-1.2-5.71-4.3-8.83-10.79-8.85-7.58-.03-11.65,6.67-11.68,13.38-.03,6.35,5,13.23,11.86,13.26,6.07,.03,9.76-4,11.66-7.89l4.97,2.98c-3.27,6.05-8.34,10.15-17.37,10.11-10.33-.04-17.37-8.16-17.33-18.48Z"/>
        <path d="M154.3,.44l6.21,.03-.11,27c3.48-3.16,8.62-5.74,13.96-5.72,9.82,.04,14.35,5.47,14.31,14.86l-.09,21.73-6.21-.03,.08-19.42c.03-6.79-2.26-11.2-9.41-11.23-4.69-.02-9.47,3.28-12.66,6.59l-.1,23.97-6.21-.03,.24-57.76Z"/>
        <path d="M197.12,.62l6.21,.03-1.14,13.64-4.04-.02-1.03-13.65Z"/>
        <path d="M210.38,40.53c.04-10.47,8.24-18.52,18.06-18.48,5.42,.02,9.45,1.84,12.18,5.03l.11-26.28,6.21,.03-.24,57.76-6.21-.03,.02-4.91c-2.76,3.38-6.88,5.39-12.22,5.37-10.9-.04-17.95-8.02-17.9-18.49Zm18.57,13.29c7.94,.03,11.58-6.31,11.61-13.6,.03-7.15-3.92-12.94-11.5-12.97-8.01-.03-12.45,6.16-12.48,13.38-.03,7.87,5.79,13.17,12.37,13.19Z"/>
      </g>
      <g>
        <g>
          <rect fill="#ffc900" y="69.87" width="49.53" height="49.4"/>
          <rect fill="#05f293" x="49.33" y="69.87" width="49.53" height="49.4"/>
          <rect fill="#00e5f0" x="98.66" y="69.87" width="49.53" height="49.4"/>
          <rect fill="#ffc900" x="147.99" y="69.87" width="49.53" height="49.4"/><rect fill="#05f293" x="197.33" y="69.87" width="49.53" height="49.4"/>
        </g>
        <g>
          <path d="M17.12,104v-21.56h8.68c1.32,0,2.47,.16,3.45,.48,.98,.32,1.8,.79,2.44,1.4,.64,.61,1.13,1.36,1.45,2.24,.32,.88,.48,1.88,.48,3.01s-.16,2.12-.48,3c-.32,.88-.8,1.63-1.45,2.25-.64,.61-1.46,1.08-2.44,1.4-.98,.32-2.13,.48-3.45,.48h-4.58v7.31h-4.1Zm4.1-11.11h4.55c.62,0,1.16-.07,1.62-.22,.46-.14,.85-.36,1.17-.64s.55-.63,.71-1.04c.16-.41,.24-.89,.24-1.43s-.08-1.02-.24-1.43c-.16-.41-.4-.76-.71-1.04s-.7-.49-1.17-.64c-.46-.14-1.01-.22-1.62-.22h-4.55v6.65Z"/>
          <path d="M68.38,103.96v-21.56h4.1v17.76h9.28v3.8h-13.39Z"/>
          <path d="M128.87,103.96h4.3l-6.99-21.56h-5.49l-6.99,21.56h4.3l1.43-4.49h8.03l1.43,4.49Zm-8.25-8.29l2.81-8.85,2.81,8.85h-5.62Z"/>
          <path d="M168.58,103.6c-1.29-.54-2.39-1.3-3.29-2.29s-1.59-2.17-2.07-3.55c-.48-1.38-.73-2.91-.73-4.58s.24-3.2,.73-4.58c.48-1.38,1.18-2.56,2.07-3.55s1.99-1.75,3.29-2.29c1.29-.54,2.75-.81,4.36-.81,1.24,0,2.38,.16,3.42,.48,1.04,.32,1.98,.77,2.81,1.36,.83,.59,1.54,1.31,2.15,2.15,.6,.84,1.08,1.79,1.42,2.84l-4.1,1.14c-.2-.65-.47-1.23-.81-1.74-.34-.51-.75-.95-1.22-1.3-.47-.35-1.02-.62-1.62-.81-.61-.18-1.29-.28-2.04-.28-.99,0-1.86,.17-2.63,.52s-1.41,.85-1.93,1.49c-.52,.64-.92,1.42-1.2,2.33s-.41,1.93-.41,3.05,.13,2.13,.39,3.05,.65,1.69,1.16,2.34c.51,.64,1.15,1.14,1.92,1.49,.77,.35,1.67,.52,2.69,.52,.75,0,1.43-.09,2.04-.28,.61-.18,1.15-.45,1.62-.81,.47-.35,.88-.79,1.22-1.3,.34-.51,.61-1.1,.81-1.74l4.1,1.14c-.34,1.05-.81,1.99-1.42,2.84-.6,.84-1.32,1.56-2.15,2.15-.83,.59-1.76,1.04-2.81,1.36-1.04,.32-2.18,.48-3.42,.48-1.62,0-3.07-.27-4.36-.81Z"/>
          <path d="M215.34,103.96v-21.56h14.1v3.8h-10v4.61h8.21v3.8h-8.21v5.54h10v3.8h-14.1Z"/>
        </g>
      </g>
    </svg>
);

export default SVG;