import React from 'react';
import Link from '../link';
import LiveBadge from '../live-badge';

export default function UserRank({ leaderboardIndex, username, usernameLink, avatarURI, isLive, leaderboardValue, showLoading, className }) {
  return (
    <li className={`flex items-center ${className}`}>
      <div className="flex flex-1 items-center">
        <span className="font-medium text-lg mr-2">{leaderboardIndex}.</span>
        {showLoading === true ? (
          <>
            <div className="w-7 h-7 bg-gray-200 animate-pulse mr-2 rounded-full shadow-mimic-0" />
            <div className="w-24 md:w-36 h-5 bg-gray-200 animate-pulse" />
          </>
        ) : (
          <>
            <img
              src={avatarURI}
              alt={`${username}'s Avatar`}
              className="w-7 mr-2 rounded-full shadow-mimic-0"
            />
            <Link href={usernameLink} className="mr-2">
              {username}
            </Link>
            {isLive && <LiveBadge />}
          </>
        )}
      </div>
      <div>
        {showLoading === true ? (
          <div className="w-12 h-5 bg-gray-200 animate-pulse" />
        ) : (
          leaderboardValue
        )}
      </div>
    </li>
  );
}
