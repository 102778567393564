import React from "react";

export default function UserMenu({
    provider,
    providerId,
    isBanned,
    handleBanUnbanUser,
}) {

    return (
        <div className='ml-4 md:ml-0 md:mt-4 text-md'>
            <button
                className={'flex transition-all duration-75 bg-white hover:bg-slate-100 active:bg-slate-200 p-2 rounded border-2 border-black cursor-pointer select-none'}
                onClick={e => handleBanUnbanUser(provider, providerId, !isBanned)}
            >
                {isBanned ? 'Unban User' : 'Ban User'}
            </button>
        </div>
    );
}
