import { Container, Graphics, Sprite } from "pixi.js";
import { getColorFromId } from "../../../services/color";
import selectorLayer1 from "./selector-1.png";
import selectorLayer2 from "./selector-2.png";

const SELECTION_BOX_ALPHA = 0.75;
const SELECTION_BOX_SCALE = 0.111;

export default class Canvas {
  constructor(width, height) {
    // Setup
    this.map = new Container();
    this.tileMap = new Map();
    // Map BG
    this.canvasBackground = this.buildCanvasBackground(width, height);
    this.map.addChild(this.canvasBackground);
    // Preview Tile
    this.previewTileEnabled = false;
    this.previewTile = this.buildPreviewTile();
    this.map.addChild(this.previewTile);
    // Selection Box
    this.selectionBox = this.buildSelectionBox();
    this.map.addChild(this.selectionBox);
    // Temporary Tile
    this.temporaryTilePlaced = false;
    this.temporaryTile = {
      x: null,
      y: null,
      id: null,
      previousColor: null,
      changed: false,
    };
  }

  getMap() {
    return this.map;
  }

  buildCanvasBackground(width, height) {
    const background = new Graphics();
    background.beginFill(0xffffff);
    background.drawRect(0, 0, width, height);
    background.endFill();
    return background;
  }

  createTileGraphicsObject(color) {
    const tile = new Graphics();
    tile.beginFill(0xffffff);
    tile.drawRect(0, 0, 1, 1);
    tile.endFill();
    tile.tint = color;

    return tile;
  }

  buildSelectionBox() {
    const container = new Container();
    // Layers
    const sprite_layer_1 = Sprite.from(selectorLayer1); // White
    sprite_layer_1.alpha = 0.7;
    const sprite_layer_2 = Sprite.from(selectorLayer2); // Grey
    // Set Container Vars
    container.alpha = SELECTION_BOX_ALPHA;
    container.addChild(sprite_layer_1);
    container.addChild(sprite_layer_2);
    container.scale.set(SELECTION_BOX_SCALE);

    return container;
  }

  setSelectionBoxPosition(x, y) {
    this.selectionBox.x = x;
    this.selectionBox.y = y;
  }

  hideSelectionBox() {
    this.selectionBox.alpha = 0;
  }

  showSelectionBox() {
    this.selectionBox.alpha = SELECTION_BOX_ALPHA;
  }

  setPreviewTilePosition(x, y) {
    this.previewTile.x = x;
    this.previewTile.y = y;
  }

  setPreviewTileColor(colorId) {
    this.previewTile.tint = getColorFromId(colorId);
  }

  getPreviewTileEnabled() {
    return this.previewTileEnabled;
  }

  enablePreviewTile() {
    this.showPreviewTile = true;
    this.previewTile.alpha = 1;
  }

  disablePreviewTile() {
    this.showPreviewTile = false;
    this.previewTile.alpha = 0;
  }

  buildPreviewTile() {
    const previewTile = this.createTileGraphicsObject(0xffffff);
    previewTile.alpha = 0;
    return previewTile;
  }

  addTemporaryTile(x, y, colorId) {
    const id = `${x},${y}`;
    const tile = this.tileMap.get(id);
    const previousColor = tile ? tile.tint : 0xffffff;

    this.addTile(x, y, colorId);

    this.temporaryTilePlaced = true;
    this.temporaryTile = {
      x,
      y,
      id,
      previousColor,
      changed: false,
    };
  }

  removeTemporaryTile() {
    this.temporaryTilePlaced = false;
    this.temporaryTile = {
      x: null,
      y: null,
      id: null,
      previousColor: null,
      changed: false,
    };
  }

  revertTemporaryTile() {
    if (this.temporaryTile.changed === false) {
      const tile = this.tileMap.get(this.temporaryTile.id);
      tile.tint = this.temporaryTile.previousColor;
    }
    this.removeTemporaryTile();
  }

  addTile(x, y, colorId) {
    const id = `${x},${y}`;
    const color = getColorFromId(colorId);
    const tile = this.tileMap.get(id);

    if (tile && this.temporaryTilePlaced && this.temporaryTile.id === id)
      this.temporaryTile.changed = true;

    if (!tile) {
      const tileObj = this.createTileGraphicsObject(color);
      tileObj.x = x;
      tileObj.y = y;
      return this.tileMap.set(id, this.map.addChildAt(tileObj, 1));
    }

    tile.tint = color;
  }
}
