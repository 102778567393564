import React from 'react'

const classDefault = 'w-24 md:w-28 rounded-full shadow-mimic-1 ml-5 md:ml-0 md:mt-3';

export default function Avatar({ loading, uri, username }) {
  return loading
    ? (
      <div className={`animate-pulse h-24 md:h-28 bg-gray-200 rounded-full ${classDefault}`}></div>
    )
    : (
      <img className={classDefault} src={uri} alt={`${username} Avatar`} />
    );
}
