export const COLOR = [
  0xFFFFFF,
  0xE4E4E4,
  0x888888,
  0x222222,
  0xFFA7D1,
  0xE50000,
  0xE59500,
  0xA06A42,
  0xE5D900,
  0x94E044,
  0x02BE01,
  0x00E5F0,
  0x0083C7,
  0x0000EA,
  0xE04AFF,
  0x820080
];

export const COLOR_STRING = [
  '#FFFFFF',
  '#E4E4E4',
  '#888888',
  '#222222',
  '#FFA7D1',
  '#E50000',
  '#E59500',
  '#A06A42',
  '#E5D900',
  '#94E044',
  '#02BE01',
  '#00E5F0',
  '#0083C7',
  '#0000EA',
  '#E04AFF',
  '#820080'
];

export const getColorFromId = Id => COLOR[Id];

export const getRandomColorId = () => Math.floor(Math.random() * COLOR.length);