import React from "react";

export default function PageContainer({ className = "", children }) {
  return (
    <div
      className={`flex flex-col max-w-screen-xl p-2 md:p-3 mx-auto ${className}`}
    >
      {children}
    </div>
  );
}
