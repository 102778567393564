// import { io } from "socket.io-client";

const socket = new WebSocket('wss://glitchd.place');
socket.binaryType = "arraybuffer"

socket.addEventListener("open", () => console.log("Connected <Socket>"));
socket.addEventListener("close", () => console.log("Disconnected <Socket>"));

socket.addEventListener("message", event => {
  const buffer = event.data;

  // Read Event Type
  const bufferArray = new Uint16Array(buffer);
  const eventType = bufferArray[0];

  switch(eventType) {
    // Add Tile
    case 1:
      const x = bufferArray[1];
      const y = bufferArray[2];
      const colorId = bufferArray[3];
      console.log(`Adding tile to: ${x}, ${y}, color ${colorId}`);
      if (updateCanvas !== null) updateCanvas(x, y, colorId);
      else tileQueue.push({ x, y, colorId });
      break;
    // Ping
    case 0:
      socket.send(new Uint8Array(1));
      break;
  }
});

let tileQueue = [];
let updateCanvas;

export const onAddTile = (cb) => {
  updateCanvas = cb;
  // Clear bucket now that we registered handler
  tileQueue.forEach(({ x, y, colorId }) => cb(x, y, colorId));
  tileQueue = [];
};
