import React from "react";
import Twemoji from "../../../components/twemoji";

export default function MuteButton({ volumeSetting, onClick }) {
  const getVolumeIcon = (volumeSetting) => {
    switch (volumeSetting) {
      case 3:
        return "🔊";
      case 2:
        return "🔉";
      case 1:
        return "🔈";
      case 0:
        return "🔇";
      default:
        return "🔇";
    }
  };

  return (
    <button
      className="flex transition-all duration-75 bg-white hover:bg-slate-100 active:bg-slate-200 p-2 rounded border-2 border-black cursor-pointer select-none"
      onClick={onClick}
    >
      <Twemoji emoji={getVolumeIcon(volumeSetting)} className="w-6 md:w-4" />
    </button>
  );
}
