import React from "react";
import Twemoji from "../twemoji";
import LiveBadge from "../live-badge";
import LinePattern from "../line-pattern";
import Draggable from "react-draggable";

export default function News({
  title = "What is this place?",
  type = "News & Updates",
  markdownBody,
}) {
  return (
    <div>
      <div className="flex bg-neutral-200 border border-violet-900 w-full rounded-t">
        <div className="flex flex-col grow p-4">
          <div className="flex items-center font-hkGrotesk font-extrabold text-2xl">
            <Draggable>
              <Twemoji emoji="📌" className="w-6 mr-2 pointer-events-auto" />
            </Draggable>
            News & Updates
          </div>
        </div>
      </div>
      <div className="flex flex-col grow p-4 border-l border-r border-b border-violet-900 last:rounded-b bg-white space-y-4">
        <div className="space-y-1">
          <div className="flex items-center">
            <div className="font-hkGrotesk font-bold text-sm mr-2 px-1 uppercase border border-2 border-black">
              News
            </div>
            <div className="font-hkGrotesk font-bold text-2xl">{title}</div>
          </div>
          <div className="font-hkGrotesk font-medium text-md">
            October 2nd, 2023
          </div>
        </div>
        <LinePattern className="h-2 text-gray-500" />
        <div className="space-y-2">
          <p>
            What would it look like if Twitch created their own{" "}
            <a
              href="https://reddit.com/r/place"
              target="_blank"
              rel="noreferrer"
              className="text-violet-700 underline"
            >
              /r/place
            </a>
            ?
          </p>
          <p>
            We'd guess account integration, leaderboards, seasons, and a canvas
            to be reckoned with.
          </p>
          <p>Well, that's exactly what we made.</p>
          <p>
            Each tile you place will get you one step closer to your spot on the
            leaderboards.
          </p>
          <p>
            And while being on the leaderboards is awesome, you only have to
            place one tile to leave your mark.
          </p>
          <p>
            When others see your profile, they will also see if you're{" "}
            <span className="inline-flex">
              <LiveBadge />
            </span>{" "}
            !
          </p>
          <p>
            If you want to see how Glitch'd Place was made,{" "} 
            <a
              href="https://www.youtube.com/watch?v=NXnJUDi52ow"
              target="_blank"
              rel="noreferrer"
              className="text-violet-700 underline"
            >
              check this out.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
