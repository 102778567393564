import React from 'react';
import Card from '../card';
import UserRank from './user-rank';
import { generateProviderProfileUrl } from "../../services/user";

export default function Leaderboard({ title, users, showLoading }) {
  return (
    <Card>
      <div className="flex flex-col grow p-4">
        <div className="font-hkGrotesk font-medium text-lg">Leaderboard</div>
        <div className="font-hkGrotesk font-extrabold text-2xl mt-1.5">
          {title}
        </div>
        <ol className="list-none font-hkGrotesk mt-5 space-y-3">
          {showLoading === true ? (
            <>
              <UserRank leaderboardIndex={1} showLoading={true} />
              <UserRank
                leaderboardIndex={2}
                showLoading={true}
                className="opacity-70"
              />
              <UserRank
                leaderboardIndex={3}
                showLoading={true}
                className="opacity-40"
              />
            </>
          ) : (
            users.map((user, index) => (
              <UserRank
                key={`${user.username}-${index}`}
                leaderboardIndex={index + 1}
                username={user.username}
                usernameLink={generateProviderProfileUrl(
                  user.provider,
                  user.username
                )}
                avatarURI={user.avatarUrl}
                isLive={user.live}
                leaderboardValue={`${user.score} Tiles`}
              />
            ))
          )}
        </ol>
      </div>
    </Card>
  );
}
