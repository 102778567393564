export const calculateTimeUntil = date => {
  // let difference = Date.parse(date) - Date.now();
  let difference = date - Date.now();

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      total: difference,
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.ceil((difference / 1000) % 60)
    };
  }

  return timeLeft;
}