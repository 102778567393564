import React from 'react';
import { COLOR, COLOR_STRING } from '../../../../services/color';
import InputButton from '../../../../components/input/input-button';

export default function TileControls({ selectedColor, onColorSelected }) {
  return (
    <div className='grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-16 gap-2'>
      { COLOR.map((color, index) =>
        <InputButton
          key={index}
          // className='w-16 h-10'
          className='flex-1 h-10'
          inputType='radio'
          inputId={index}
          inputName='tileColorSwatch'
          backgroundColor={COLOR_STRING[index]}
          backgroundColorHover={COLOR_STRING[index]}
          backgroundColorChecked={COLOR_STRING[index]}
          checked={selectedColor === index}
          onChange={() => onColorSelected(index)}
        />
      )}
    </div>
  )
}
