import React, { useState } from 'react';

export default function StyledInputButton({
  inputType = 'checkbox',
  inputId = '',
  inputName = '',
  inputValue = '',
  className = '',
  backgroundColor,
  backgroundColorHover,
  backgroundColorChecked,
  checked,
  onChange,
  children
}) {
  const [hover, setHover] = useState(false);

  const additionalStyle = () => {
    if (!hover && backgroundColor)
      return {
        backgroundColor: backgroundColor
      }
    else if (hover && backgroundColorHover)
      return {
        backgroundColor: backgroundColorHover
      }
    else if (checked && backgroundColorChecked)
      return {
        backgroundColor: backgroundColorChecked
      }
  };

  return (
    <div className="flex p-1">
      <input
        className='input-button peer'
        type={inputType}
        id={inputId}
        name={inputName}
        value={inputValue}
        checked={checked}
        onChange={onChange}
      />
      <label
        htmlFor={inputId}
        className={`${className}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={additionalStyle()}
      >
        {children}
      </label>
    </div>
  )
}
