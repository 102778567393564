import React from 'react';
import PageContainer from '../components/page-container';
import SeasonInfo from '../feature/season-info';
import PlaceArea from '../containers/PlaceArea';
import News from '../components/news';
import Leaderboards from '../feature/leaderboards';

export default function Home() {
    return (
      <PageContainer className="min-h-screen">
        <SeasonInfo className="py-5" />
        <PlaceArea />
        {/* news + leaderboards */}
        <div className="grid grid-cols-1 md:grid-cols-5 gap-2 mt-8">
          <div className="col-span-1 md:col-span-3">
            <News />
          </div>
          <div className="sm:grid sm:grid-cols-2 md:flex md:flex-col order-first md:order-none col-span-1 md:col-span-2 sm:space-x-2 md:space-x-0 space-y-2 md:space-y-2 sm:space-y-0">
            <Leaderboards />
          </div>
        </div>
      </PageContainer>
    );
}
