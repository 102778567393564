import React from 'react'

export default function LiveBadge() {
  return (
    <span className='relative select-none inline-block font-hkGrotesk font-medium text-xs bg-red-400 text-white px-1 py-0.5'>
      <span className='relative z-10'>LIVE</span>
      <span className='animate-ping absolute inset-0 bg-red-400 z-0'></span>
    </span>
  )
}
