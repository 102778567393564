import React from "react";
import { Link } from "react-router-dom";

const lineColors = [
  "#fb923c",
  "#fbbf24",
  "#facc15",
  "#a3e635",
  "#4ade80",
  "#818cf8",
  "#34d399",
  "#2dd4bf",
  "#22d3ee",
  "#e879f9",
  "#f472b6",
  "#fb7185",
];

export default function StyledLink({
  to = null,
  href = null,
  fontFamily = "hkGrotesk",
  fontWeight = "medium",
  lineColor = "random",
  textWhiteOnHover,
  className,
  children,
}) {
  const textColorOnHover = textWhiteOnHover ? "group-hover:text-white" : "";
  const lineBgColor =
    lineColor === "random"
      ? lineColors[Math.floor(Math.random() * lineColors.length)]
      : lineColor;
  const linkClass = `group relative inline-block font-${fontFamily} font-${fontWeight} cursor-pointer ${className}`;

  const DynamicLink = ({ to, href, children }) =>
    to !== null ? (
      <Link className={linkClass} to={to}>
        {children}
      </Link>
    ) : (
      <a className={linkClass} href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    );

  return (
    <DynamicLink to={to} href={href}>
      <span className={`relative z-10 ${textColorOnHover}`}>{children}</span>
      <div
        className={`block origin-bottom absolute inset-0 pointer-events-none scale-y-[.1] group-hover:scale-y-100 bg-${lineBgColor} transition-transform ease-in-out duration-[60ms] -bottom-1 z-0`}
        style={{ background: lineBgColor }}
      ></div>
    </DynamicLink>
  );
}
