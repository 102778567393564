import React from 'react';
import LiveBadge from '../../../../components/live-badge';
import Link from '../../../../components/link';

const Username = ({ username, usernameLink, textWhiteOnHover }) => (
  <Link href={usernameLink} textWhiteOnHover={textWhiteOnHover}>{username}</Link>
);

export default function UserDetails({ username, usernameLink, isLive, loading }) {
  return (
    <div className='flex flex-col ml-4 md:ml-0'>
      <div className='md:mt-3 text-xl text-lg font-medium'>
        { loading
          ? <div className='h-6 w-24 bg-gray-200 animate-pulse mt-2'></div>
          : <Username username={username} usernameLink={usernameLink} textWhiteOnHover={true} />
        }
        { isLive &&
            <div className='mt-2 md:mt-3'>
              <LiveBadge />
            </div>
        }
      </div>
    </div>
  )
}
