import React from "react";
import PeepoTrophy from "../../assets/hero/peepo-trophy";
import StarShine from "../../assets/hero/star-shine";
import Twemoji from "../../components/twemoji";
import Draggable from "react-draggable";

export default function SeasonInfo({ className = "" }) {
  return (
    <div
      className={`flex flex-col sm:flex-row justify-center sm:justify-evenly md:justify-start items-center mb-2 md:mx-auto ${className}`}
    >
      <div className="flex relative md:mr-12">
        <StarShine className="absolute top-0 w-36 z-0 animate-spin-slow" />
        <Draggable>
          <div className="flex">
            <PeepoTrophy className="relative w-36 z-10 animate-wiggle" />
          </div>
        </Draggable>
      </div>
      <div className="flex flex-col mt-8 sm:mt-0 items-center md:items-start md:justify-center space-y-2">
        <div className="flex">
          <span className="bg-[#F1F333] rounded px-2.5 border border-black font-mono text-2xl">
            SEASON 1 ENDING IN:
          </span>
        </div>
        <div className="flex relative font-hkGrotesk">
          <div className="hkGrotesk absolute top-0 -left-7 h-full flex items-center">
            <Draggable>
              <Twemoji emoji="🔒" className="w-6 pointer-events-auto" />
            </Draggable>
          </div>
          <span className="text-neutral-600 font-bold text-6xl timer-text-shadow">
            14:23:59:59
          </span>
        </div>
      </div>
    </div>
  );
}
