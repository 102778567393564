import {
  requestTileDetails,
  requestSetUserIsBanned
} from '../../services/api';

const initialState = {
  showSelectedTile: false,
  selectedTile: null,
  currentCoords: null,
  fetchingSelectedTile: false,
  fetchingSelectedTileAt: null,
};

export const tileDetails = {
  state: initialState,
  reducers: {
    reset: (state, payload) => initialState,
    setSelectedTile: (state, tile) => ({
      ...state,
      selectedTile: tile,
      fetchingSelectedTile: false,
    }),
    fetchingSelectedTile: (state, { x, y, timestamp }) => ({
      ...state,
      showSelectedTile: true,
      currentCoords: { x, y },
      fetchingSelectedTile: true,
      selectedTimestamp: timestamp,
    }),
    updateSelectedTileOwner: (
      state,
      { provider, providerId, username, avatarUri, live, colorId, isBanned }
    ) => ({
      ...state,
      selectedTile: {
        ...state.selectedTile,
        colorId,
        user: {
          avatarUri,
          live,
          provider,
          providerId,
          username,
          isBanned
        },
      },
    }),
    updateSelectedTileOwnerBanStatus: (
      state,
      { isBanned }
    ) => ({
      ...state,
      selectedTile: {
        ...state.selectedTile,
        user: {
          ...state.selectedTile.user,
          isBanned,
        },
      },
    }),
  },
  effects: (dispatch) => ({
    async getTile({ x, y, timestamp }, rootState) {
      const tile = await requestTileDetails(x, y, timestamp);

      if (tile.clientTimestamp < rootState.tileDetails.lastSelectedTime) return;

      if (tile.success === false)
        return dispatch.tileDetails.setSelectedTile(null);

      dispatch.tileDetails.setSelectedTile({
        colorId: tile.colorId,
        user: {
          avatarUri: tile.user.avatarUrl,
          live: tile.user.live,
          provider: tile.user.provider,
          providerId: tile.user.providerId,
          username: tile.user.username,
          isBanned: tile.user.isBanned || null,
        },
      });
    },
    async tileSelected({ x, y, timestamp }, rootState) {
      if (
        timestamp !== null &&
        timestamp < rootState.tileDetails.selectedTimestamp
      )
        return;
      dispatch.tileDetails.fetchingSelectedTile({ x, y, timestamp });
      dispatch.tileDetails.getTile({ x, y, timestamp });
    },
    async banUnbanUser({ provider, providerId, isBanned }, rootState) {
      if (!rootState.currentUser.isModerator) return;
      
      const request = await requestSetUserIsBanned(provider, providerId, isBanned);
      if (request.success === true) {
        const { provider: currentProvider, providerId: currentProviderId } = rootState.tileDetails.selectedTile.user;
        if (currentProvider === provider && currentProviderId === providerId)
          dispatch.tileDetails.updateSelectedTileOwnerBanStatus({ isBanned });
      }
    }
  }),
};