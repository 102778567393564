import React, { memo } from "react";
import twemoji from "twemoji";

const Twemoji = ({
  emoji,
  className,
  style,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
}) => (
  <span
    dangerouslySetInnerHTML={{
      __html: twemoji.parse(emoji, {
        folder: "svg",
        ext: ".svg",
      }),
    }}
    className={`pointer-events-none ${className}`}
    style={style}
    onMouseDown={onMouseDown}
    onMouseUp={onMouseUp}
    onTouchStart={onTouchStart}
    onTouchEnd={onTouchEnd}
  />
);

export default memo(Twemoji);
