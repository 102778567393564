import React from "react";
import Avatar from "./user-avatar";
import UserDetails from "./user-details";
import { generateProviderProfileUrl } from "../../../services/user";
import UserMenu from "./user-menu";

export default function PlaceTileDetails({
  fetchingDetails,
  showDetails,
  currentUserIsModerator,
  tileX,
  tileY,
  userAvatarUri,
  provider,
  providerId,
  username,
  userLive,
  isBanned,
  handleBanUnbanUser
}) {

  const TileDetails = () =>
    fetchingDetails === true || (fetchingDetails === false && !!username) ? (
      <>
        <div className="font-mono">
          ({tileX + 1}, {tileY + 1})
        </div>
        <Avatar
          uri={userAvatarUri}
          username={username}
          loading={fetchingDetails}
        />
        <UserDetails
          provider={provider}
          username={username}
          usernameLink={generateProviderProfileUrl(provider, username)}
          isLive={userLive}
          loading={fetchingDetails}
        />
        {
          fetchingDetails === false && currentUserIsModerator && (
            <UserMenu
              provider={provider}
              providerId={providerId}
              isBanned={isBanned}
              handleBanUnbanUser={handleBanUnbanUser}
            />
          )
        }
      </>
    ) : (
      <>
        <div className="font-mono">
          ({tileX + 1}, {tileY + 1})
        </div>
        <span className="text-sm ml-2 md:ml-0 md:mt-2">empty space</span>
      </>
    );

  return (
    <div className="flex font-hkGrotesk md:flex-col flex-1 justify-center items-center text-center p-3">
      {showDetails ? (
        <TileDetails />
      ) : (
        <span className="text-sm">click a tile to view details</span>
      )}
    </div>
  );
}
