import React from 'react';
import { connect } from "react-redux";
import Home from "../../pages/home";

const mapProps = state => ({
  currentUser: state.currentUser,
});

const mapDispatch = dispatch => ({
  handleLogout: () => dispatch.currentUser.logout(),
});

export default connect(mapProps, mapDispatch)(Home);