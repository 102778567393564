import React, { useEffect } from "react";

export default function Auth() {
  useEffect(() => {
    if (window.opener) {
      window.opener.focus();
      window.opener.location.reload();
    }
    window.close();
  }, []);

  return <div>Redirecting...</div>;
}
