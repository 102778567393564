export const createPopupWindow = (url, windowName, window, width, height) => {
  const y = window.top.outerHeight / 2 + window.top.screenY - height / 2;
  const x = window.top.outerWidth / 2 + window.top.screenX - width / 2;
  return window.open(
    url,
    windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`
  );
};

export const createTwitchAuthPopupWindow = () =>
  createPopupWindow(
    "http://glitchd.place/api/auth/twitch",
    null,
    window,
    600,
    800
  );
